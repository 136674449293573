import React from 'react';
import { NaturalSelectionSoloMode } from '@cof/natural-selection';
import Redirect from '../components/Redirect/Redirect';
import PropTypes from 'prop-types';
import { getCorrelationId } from '../lib/correlationId';

const Affiliates = ({ location }) => {
  const { NS, Control, Variation } = NaturalSelectionSoloMode;
  let url = new URL(window.location.href);
  const brand = url.searchParams.get('brand');

  const correlationId = getCorrelationId();

  const PDPTargetURL = {
    'approbation-garantie': '/credit-cards/guaranteed-mastercard/fr/',
    'recompenses-intelligentes': '/credit-cards/smart-rewards-mastercard/fr/',
    garantie: '/credit-cards/guaranteed-secured-mastercard2/fr/',
    guaranteed: '/credit-cards/guaranteed-mastercard/',
    secured: '/credit-cards/guaranteed-secured-mastercard2/',
    smartrewards: '/credit-cards/smart-rewards-mastercard/',
  };

  const QKTargetURL = {
    'approbation-garantie': `https://findyourcard.capitalone.ca/?qkref=Z20%3D&lang=fr-ca&corid=${correlationId}`,
    'recompenses-intelligentes': `https://findyourcard.capitalone.ca/?qkref=bmZzcm0%3D&lang=fr-ca&corid=${correlationId}`,
    garantie: `https://findyourcard.capitalone.ca/?qkref=c2docg%3D%3D&lang=fr-ca&corid=${correlationId}`,
    guaranteed: `https://findyourcard.capitalone.ca/?qkref=Z20%3D&corid=${correlationId}`,
    secured: `https://findyourcard.capitalone.ca/?qkref=c2docg%3D%3D&corid=${correlationId}`,
    smartrewards: `https://findyourcard.capitalone.ca/?qkref=bmZzcm0%3D&corid=${correlationId}`,
  };

  const navigationDelay = 250;

  return (
    <div>
      <iframe
        title="iframe"
        referrerPolicy="same-origin"
        src="https://findyourcard.capitalone.ca"
        height="0"
        width="0"
        style={{ display: 'none', visibility: 'hidden' }}
        sandbox=""
      ></iframe>

      <NS experimentName="credit-karma-skip-test">
        <Control weight={0.5}>
          <Redirect
            pageContext={{ toPath: PDPTargetURL[brand] }}
            location={location}
            replace={false}
            navigationDelay={navigationDelay}
          />
        </Control>

        <Variation weight={0.5} name="quickcheck">
          <Redirect
            pageContext={{ toPath: QKTargetURL[brand] }}
            location={location}
            replace={false}
            navigationDelay={navigationDelay}
          />
        </Variation>
      </NS>
    </div>
  );
};
Affiliates.propTypes = {
  location: PropTypes.objectOf(PropTypes.string),
};
export default Affiliates;
